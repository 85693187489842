import React from "react"
import { Nav, Navbar } from "react-bootstrap"
import { useStaticQuery, graphql } from "gatsby"

const Header = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          author
        }
      }
    }
  `)
  return (
    <Navbar bg="light" variant="light" expand="md">
      <Navbar.Brand href="/">{data.site.siteMetadata.title}</Navbar.Brand>
      <Nav className="mr-auto">
        <Nav.Link href="/podcasts">Podcasts</Nav.Link>
        <Nav.Link href="/about">About</Nav.Link>
      </Nav>
      <Nav className="ml-auto">
        <Nav.Link href="#features">Contact</Nav.Link>
      </Nav>
    </Navbar>
  )
}

export default Header
